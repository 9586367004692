import { render, staticRenderFns } from "./personInfoList.vue?vue&type=template&id=61ce7a84&scoped=true&"
import script from "./personInfoList.vue?vue&type=script&lang=js&"
export * from "./personInfoList.vue?vue&type=script&lang=js&"
import style0 from "./personInfoList.vue?vue&type=style&index=0&id=61ce7a84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ce7a84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61ce7a84')) {
      api.createRecord('61ce7a84', component.options)
    } else {
      api.reload('61ce7a84', component.options)
    }
    module.hot.accept("./personInfoList.vue?vue&type=template&id=61ce7a84&scoped=true&", function () {
      api.rerender('61ce7a84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/thys/view/userHome/component/personInfoList.vue"
export default component.exports