<template>
	<div class="manuscriptIncomeCompenent">
		<div class="title">作者管理</div>
		<div class="operate">
			<el-input style="width: 260px;" size="small" @input="inputValue" v-model="searchValue" placeholder="请输入作者姓名、单位、手机号检索"></el-input>
		</div>
		<div class="tableList" ref="tableList">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
				fit
        stripe
        :height="tableHeight"
				@row-click="rowClick"
        style="width: 100%">
        <el-table-column
          prop="userName"
          label="用户名">
        </el-table-column>
				<el-table-column
          prop="realName"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="company"
          label="工作单位">
        </el-table-column>
				<el-table-column
          prop="wxNumber"
          label="微信号">
        </el-table-column>
				<el-table-column
          prop="phone"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="idCard"
          label="身份证号">
        </el-table-column>
        <el-table-column
          prop="bankCardNumber"
          label="银行卡号">
        </el-table-column>
				<el-table-column
          prop="submissionCount"
					width="100"
          label="投稿文章数">
        </el-table-column>
				<el-table-column
          prop="publishCount"
					width="100"
          label="发布文章数">
        </el-table-column>
				<el-table-column
          prop="totalReadCount"
					width="100"
          label="总阅读量">
        </el-table-column>
				<el-table-column
          prop="totalFee"
          label="总稿酬">
        </el-table-column>
				<el-table-column
					prop="auditStatus"
					width="100"
					label="状态">
					<template slot-scope="scope">
						<div :style="{color: scope.row.auditStatus==0 ? '#EDB055' : scope.row.auditStatus==1 ? '#23C520' : '#EE5D3D'}" class="status">{{scope.row.auditStatus==0 ? '待审核' : scope.row.auditStatus==1 ? '已审核' : '审核失败'}}</div>
					</template>
				</el-table-column>
      </el-table>
    </div>
		<div class="pagination">
      <Pagination :total="total" :limit="pageSize" :currentPage="currentPage" @pagination="pagination"></Pagination>
    </div>
	</div>
</template>
<script>
import {personInfoList} from '../../../api/personInfo.js'
import { closePage, pushRoute } from '@/utils/routerUtil'
export default {
  name: 'personInfoList',
  data() {
    return {
			searchValue: '',
			tableData: [],
			tableHeight: 0,
			total: 0,
			pageSize: 10,
			currentPage: 1,
			loading: false,
    }
  },
	activated() {
		this.getTable()
		},
  created() {
  },
	mounted() {
		window.addEventListener('resize', () => {
			this.tableHeight = 0
			this.$nextTick(() => {
        this.tableHeight = this.$refs.tableList.clientHeight
      })
    })
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableList.clientHeight
    })
	},
  methods: {
		async getTable() {
			this.loading = true
			const params = {
				pageNum: this.currentPage,
				pageSize: this.pageSize,
				month: '',
				searchValue: this.searchValue,
			}
			let res = await personInfoList(params)
			this.loading = false
			if(res.code == 200) {
				this.tableData = res.rows
				this.total = res.total
			}
		},
		rowClick(row) {
			pushRoute(`/userHome/personInfo?personId=${row.id}`, true)
			// this.$router.push({
      //   path: '/userHome/personInfo?personId=' + row.id,
      // })
			// this.$emit('selectPersonInfo', row.id)
		},
		inputValue() {
			this.currentPage = 1
			this.getTable()
		},
		pagination(val) {
      this.pageSize = val.limit
			this.currentPage = val.page
      this.getTable()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-table__cell {
	padding: 8px 12px !important;
}
.manuscriptIncomeCompenent {
	padding: 36px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100vh;
	.operate {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
	}
	.pagination {
    //margin-top: 20px;
    border-top: unset;
    flex: 0 0 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBEEF5;
    border-radius: 0px 0px 8px 8px;
  }
	.tip {
		color: #808388;
		font-size: 12px;
		line-height: 23px;
		margin-top: 20px;
	}
	.tableList {
    flex: 1;
    overflow-y: hidden;
		.status {
			line-height: 23px;
			font-size: 14px;
		}
	}
	.title {
		color: #303133;
		font-size: 20px;
		font-weight: bold;
		line-height: 20px;
		margin-bottom: 32px;
	}
}
</style>