var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manuscriptIncomeCompenent" }, [
    _c("div", { staticClass: "title" }, [_vm._v("作者管理")]),
    _c(
      "div",
      { staticClass: "operate" },
      [
        _c("el-input", {
          staticStyle: { width: "260px" },
          attrs: {
            size: "small",
            placeholder: "请输入作者姓名、单位、手机号检索",
          },
          on: { input: _vm.inputValue },
          model: {
            value: _vm.searchValue,
            callback: function ($$v) {
              _vm.searchValue = $$v
            },
            expression: "searchValue",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { ref: "tableList", staticClass: "tableList" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: "",
              fit: "",
              stripe: "",
              height: _vm.tableHeight,
            },
            on: { "row-click": _vm.rowClick },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "userName", label: "用户名" },
            }),
            _c("el-table-column", {
              attrs: { prop: "realName", label: "姓名" },
            }),
            _c("el-table-column", {
              attrs: { prop: "company", label: "工作单位" },
            }),
            _c("el-table-column", {
              attrs: { prop: "wxNumber", label: "微信号" },
            }),
            _c("el-table-column", {
              attrs: { prop: "phone", label: "手机号" },
            }),
            _c("el-table-column", {
              attrs: { prop: "idCard", label: "身份证号" },
            }),
            _c("el-table-column", {
              attrs: { prop: "bankCardNumber", label: "银行卡号" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "submissionCount",
                width: "100",
                label: "投稿文章数",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "publishCount",
                width: "100",
                label: "发布文章数",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "totalReadCount",
                width: "100",
                label: "总阅读量",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "totalFee", label: "总稿酬" },
            }),
            _c("el-table-column", {
              attrs: { prop: "auditStatus", width: "100", label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "status",
                          style: {
                            color:
                              scope.row.auditStatus == 0
                                ? "#EDB055"
                                : scope.row.auditStatus == 1
                                ? "#23C520"
                                : "#EE5D3D",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              scope.row.auditStatus == 0
                                ? "待审核"
                                : scope.row.auditStatus == 1
                                ? "已审核"
                                : "审核失败"
                            )
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pagination" },
      [
        _c("Pagination", {
          attrs: {
            total: _vm.total,
            limit: _vm.pageSize,
            currentPage: _vm.currentPage,
          },
          on: { pagination: _vm.pagination },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }